import ApiService from "./api.service";

export function getAuthUrl(callback_url, state = "h5") {
  const gateway = "https://open.weixin.qq.com/connect/oauth2/authorize";
  const redirect_uri = encodeURIComponent(callback_url);
  return `${gateway}?appid=${process.env.VUE_APP_MP_APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
}

export function wechatAuth(
  code,
  state,
  union_session_id = "",
  invitation_code = "",
  wx_from="wxoa"
) {
  return new Promise((resolve, reject) => {
    ApiService.post("/auth/wechatAuthForNijibot", {
      code,
      state,
      union_session_id,
      invitation_code,
      wx_from
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function signaturePack(url) {
  return new Promise((resolve, reject) => {
    ApiService.post("/auth/getWechatSignaturePack", { url })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function prepareWechatRecharge(amount) {
  return new Promise((resolve, reject) => {
    ApiService.post("/pay/prepareRecharge", { amount })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function prepareIcbcRecharge(amount) {
  return new Promise((resolve, reject) => {
    ApiService.post("/pay/prepareIcbcRecharge", { amount })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
